<template>
    <div>
        <!--form add & update university-->
        <ek-dialog
            btnText="مرحلة جديدة"
            ref="dialog"
            :isEdit="!!universityDto.id"
            :title="universityDto.id ? 'تعديل  مرحلة' : 'اضافة مرحلة '"
            @delete="deleteUni(universityDto.id)"
            placeholder="ابحث عن مرحلة محددة"
            @ok="submitForm"
            @close="resetForm"
            @search="setSearch"
        >
            <template #body>
                <validationObserver ref="dashUserForm">
                    <ek-input-text
                        v-model="universityDto.name"
                        name="اسم "
                        placeholder="ادخل اسم مرحلة"
                        label="اسم مرحلة"
                        :rules="[
                            { type: 'required', message: 'اسم مرحلة مطلوب' },
                        ]"
                    ></ek-input-text>

                    <ek-input-select
                        name="تابع للمرحلة"
                        placeholder="تابع للمرحلة"
                        label="المرحلة التابعة لها  "
                        :options="[
                {
                    name: 'مرحلة التعليم المدرسي',
                    id: 1,
                },
                {
                    name: 'مرحلة التعليم الجامعي',
                    id: 0,
                },
            ],"
                        v-model="universityDto.type"
                    ></ek-input-select>
                    <template
                        v-if="
                            universityDto.id && universityDto.facultyIds.length
                        "
                    >
                        <label>الكليات</label>
                        <b-list-group>
                            <b-list-group-item
                                v-for="item in universityDto.facultyIds"
                                :key="item"
                            >
                                {{ facultyName.find((f) => f.id == item).name }}
                            </b-list-group-item>
                        </b-list-group>
                    </template>

                    <label v-if="universityDto.type != 1" class="mt-2"
                        >صورة الجامعة</label
                    >
                    <ek-input-image
                        v-if="
                            !universityDto.imageUrl && universityDto.type != 1
                        "
                        name="img"
                        :image="
                            universityDto.imageUrl
                                ? $store.getters['app/domainHost'] +
                                  '/' +
                                  universityDto.imageUrl
                                : ''
                        "
                        accept="image/png, image/jpeg"
                        @input="uploadSubFile($event)"
                        ref="fileInput"
                    >
                        <h5>اسحب الملف او انقر للتحميل</h5>
                    </ek-input-image>

                    <b-dropdown-item
                        v-if="universityDto.type != 1 && universityDto.imageUrl"
                        href="#"
                        ref="myUploader"
                        @click="fileWindowApi(onUpload)"
                    >
                        <unicon
                            name="edit-alt"
                            fill="#2EC4B6"
                            style="width: 25px"
                        ></unicon>
                        <span>تعديل الصورة</span>
                    </b-dropdown-item>
                    <img
                        v-if="universityDto.type != 1 && universityDto.imageUrl"
                        class="h-100 w-100 rounded"
                        style="object-fit: cover"
                        :src="
                            $store.getters['app/domainHost'] +
                            '/' +
                            universityDto.imageUrl
                        "
                        alt=""
                    />
                </validationObserver>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { fileWindowApi } from "@core/utils/uploader";
export default {
    data() {
        return {
            Step: 0,
        };
    },
    computed: {
        ...mapState({
            universityDto: (state) => state.university.universityDto,
            isDialogOpen: (state) => state.university.isDialogOpen,
            facultyName: (state) => state.faculty.facultyName,
            imageUrl: (state) => state.university.imageUrl,
        }),
        ...mapGetters(["getImageUrl"]),
    },
    methods: {
        ...mapActions(["addUniversity", "updateUniversity", "addFile"]),

        fileWindowApi,
        onUpload(event) {
            if (event && event.file) {
                this.addFile({ file: event.file, folderName: "Profile" }).then(
                    (res) => {
                        this.universityDto.imageUrl = res.data;
                    }
                );
            }
        },
        //submit add & update university
        submitForm() {
            this.$refs.dashUserForm.validate().then((suc) => {
                if (suc) {
                    if (this.universityDto.id) {
                        this.updateUniversity(this.universityDto);
                        this.$refs.dialog.close();
                    } else {
                        this.addUniversity({
                            ...this.universityDto,
                            imageUrl: this.imageUrl,
                            type: this.universityDto.type,
                        })
                            .then(() => {
                                this.$refs.dialog.close();
                            })
                            .catch(() => {});
                    }
                }
            });
        },
        deleteUni(id) {
            this.$store.dispatch("deleteUniversities", [id]);
        },

        //upload img university
        // uploadFile(event) {
        //   if (event && event.file) {
        //     this.addFile({ file: event.file, folderName: "Profile" }).then((res) => {
        //       this.universityDto.imageUrl = res.data;
        //     });
        //   }
        // },

        uploadSubFile(event) {
            if (event && event.file) {
                this.addFile({ ...event, folderName: "Profile" });
            }
        },
        resetForm() {
            this.$refs.dashUserForm.reset();
            this.$refs.fileInput.reset();
            this.$store.commit("SET_UNIVERSITY_DTO");
            this.$store.commit("IS_DIALOG_OPEN", false);
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name", "facultiesCount", "studentsCount"],
                query,
            });
        },
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>

<style lang="scss">
.dropdown.b-dropdown {
    right: 0 !important;
}
</style>
