var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ek-dialog',{ref:"dialog",attrs:{"btnText":"مرحلة جديدة","isEdit":!!_vm.universityDto.id,"title":_vm.universityDto.id ? 'تعديل  مرحلة' : 'اضافة مرحلة ',"placeholder":"ابحث عن مرحلة محددة"},on:{"delete":function($event){return _vm.deleteUni(_vm.universityDto.id)},"ok":_vm.submitForm,"close":_vm.resetForm,"search":_vm.setSearch},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('validationObserver',{ref:"dashUserForm"},[_c('ek-input-text',{attrs:{"name":"اسم ","placeholder":"ادخل اسم مرحلة","label":"اسم مرحلة","rules":[
                        { type: 'required', message: 'اسم مرحلة مطلوب' } ]},model:{value:(_vm.universityDto.name),callback:function ($$v) {_vm.$set(_vm.universityDto, "name", $$v)},expression:"universityDto.name"}}),_c('ek-input-select',{attrs:{"name":"تابع للمرحلة","placeholder":"تابع للمرحلة","label":"المرحلة التابعة لها  ","options":[
            {
                name: 'مرحلة التعليم المدرسي',
                id: 1,
            },
            {
                name: 'مرحلة التعليم الجامعي',
                id: 0,
            } ],},model:{value:(_vm.universityDto.type),callback:function ($$v) {_vm.$set(_vm.universityDto, "type", $$v)},expression:"universityDto.type"}}),(
                        _vm.universityDto.id && _vm.universityDto.facultyIds.length
                    )?[_c('label',[_vm._v("الكليات")]),_c('b-list-group',_vm._l((_vm.universityDto.facultyIds),function(item){return _c('b-list-group-item',{key:item},[_vm._v(" "+_vm._s(_vm.facultyName.find(function (f) { return f.id == item; }).name)+" ")])}),1)]:_vm._e(),(_vm.universityDto.type != 1)?_c('label',{staticClass:"mt-2"},[_vm._v("صورة الجامعة")]):_vm._e(),(
                        !_vm.universityDto.imageUrl && _vm.universityDto.type != 1
                    )?_c('ek-input-image',{ref:"fileInput",attrs:{"name":"img","image":_vm.universityDto.imageUrl
                            ? _vm.$store.getters['app/domainHost'] +
                              '/' +
                              _vm.universityDto.imageUrl
                            : '',"accept":"image/png, image/jpeg"},on:{"input":function($event){return _vm.uploadSubFile($event)}}},[_c('h5',[_vm._v("اسحب الملف او انقر للتحميل")])]):_vm._e(),(_vm.universityDto.type != 1 && _vm.universityDto.imageUrl)?_c('b-dropdown-item',{ref:"myUploader",attrs:{"href":"#"},on:{"click":function($event){return _vm.fileWindowApi(_vm.onUpload)}}},[_c('unicon',{staticStyle:{"width":"25px"},attrs:{"name":"edit-alt","fill":"#2EC4B6"}}),_c('span',[_vm._v("تعديل الصورة")])],1):_vm._e(),(_vm.universityDto.type != 1 && _vm.universityDto.imageUrl)?_c('img',{staticClass:"h-100 w-100 rounded",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.$store.getters['app/domainHost'] +
                        '/' +
                        _vm.universityDto.imageUrl,"alt":""}}):_vm._e()],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }